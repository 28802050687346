import { useState } from "react";
import { getToken } from "../../extras/firebase";
import axios from "axios";

const api_dev = "http://192.168.100.72:80";
const api_dev_depa = " http://localhost:80";
const api_dev_chris = "http://192.168.1.117:80";
const api_prod = "https://api.zerodeudas.com.mx";
const api_old = "https://zerod-crm.uc.r.appspot.com";

const api = api_prod;

const asesores = {
  ilVW1Pnog2YZfxHnCaBhyUyXe0B3: "Jaime",
  FzSDUttp7sMYdpxFYaowCyZXHTi2: "Nancy",
  hL62KFPq5CY4x9FHGIexTp5aCZS2: "Citla",
  zfFwW1pZu7TdRx2Bju4OgOTi2o62: "Osiel",
  VQPbPwsyVmMhbD4wQXXKIujmf8V2: "Sam",
  "2TBSb8RFC5cfYm8yxw07cX7iQyr1": "Betty",
  rpfmeUzCqwSkjJwbFpwaq7Px1ll1: "Sandra",
  CdaxdmTvmKPiVE7ch3IwdLosJVw1: "Marisol",
  "5s3PdMOkhhMC32Zz4kzc0YI6gMk2": "Danna",
  s9mWojqGsVaqT2d8oCZLrwNGBe62: "Ricardo",
  nnA9hmiUd7QwLNV9S219NZN3Q852: "Freddy",
  ymglgJpYJHM98coGj0dxfjSEE813: "Sonia",
  e8iTet3ZducBTeosJu9yf2DOkCs1: "Jesus",
  G7MSJJQJ4tQx3xHj6nNblSuMjkM2: "Daniela",
  "3x9sYfuoL8YN8jpYcz7ooVl9Ot43": "Ernesto",
  H5yDjLuNaEddHUvPwXpjfBSjhGw2: "Blanca",
  o3wE9g1G98hWO2TKJaefdzXl6XO2: "Lizbeth", //
  uZM6qkz0luddKQV5XH0FVTCqKug1: "Ana", //
  "30CN2MSq8SWSvniK0te3krQiT913": "Anel",
  vAFXt7CrEGboKdvj0h1hniRBYSg2: "Diana",
  MRa4cZXOVodi52pMaq6SnR26xIK2: "Bryan",
  LpPfjfM8hQS0l9vsDQ5cGWQCx3A2: "Enrique",
  sG69HABTJEN7WAxVcq0RdRMUvMf1: "Enrique Reyes",
  hFx7xCg3LkPQLqT4UoL8dA6Wrx03: "Jonathan Becerra",
  e3dlOUJkHrMPnUz2hiGgMjiD1Iv2: "Vania Morales",
  NwOkSDOLHxRyuUvzxIuroHMsbXK2: "Erika Licona",
  UXK8bUmhaOSx99hQEWJ8WU10zsX2: "Alin Yarek",

  cD5hLJPmKicme3UtzUACINBIdcF2: "Vero",
  TpfVgFGgGXdcd4DptJDawNneAiL2: "Autos",

  qfkuuBGFx6VmJ7f8AAvKi39IZSD2: "Juan Luis",
  CLD4kMC1YsXzjtj8jpyUcKxbfTq2: "Tina",
  sE2jlhd26aO7f38x8a5m946hAhg1: "Soporte",

  pWoiwgbqoCe0y72QOo5akQ6uOuW2: "Silvia",
};

const useDatabase = () => {
  const [data, setData] = useState([]);
  const [asistencia, setAsistencia] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [token, setToken] = useState(null);

  const updateLocal = (id, details) => {
    const new_data = data.map((item, index) => {
      return index === id ? details(item) : item;
    });

    setData(new_data);
  };

  const getDataGo = () => {
    setLoading(true);
    getToken().then((res) => {
      fetch(`${api}/api/clientes/getList`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "auth-token": res,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setError(null);
          setData(res.data.data == null ? [] : res.data.data);

          //setData({ id: res.data.data[0].ID, categoria: "Más de $1,000,000" });
        })
        .catch((err) => {
          setLoading(false);
          setError("Error al descargar los datos");
        });
    });
  };

  const asignarGo = async (id, asesor_nombre, asesor_id, fecha, cat) => {
    const token_temp = await getToken();
    const categoria = {
      "Menos de $49,000": "49_menos",
      "$50,000 a $75,000": "50_75",
      "$75,000 a $100,000": "75_100",
      "$100,000 a $150,000": "100_150",
      "$150,000 a $500,000": "150_500",
      "$500,000 a $1,000,000": "500_1",
      "Más de $1,000,000": "1_mas",
    }[cat];

    const dia = new Date();
    dia.setHours(dia.getHours() - 6);
    const fecha_dia = new Date(
      dia.getFullYear(),
      dia.getMonth(),
      dia.getDate()
    );

    return fetch(`${api}/api/clientes/asignar`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
      },
      body: JSON.stringify({
        id: id,
        asesor_id: asesor_id,
        asesor_nombre: asesor_nombre,
        fecha: fecha,
        dia: fecha_dia.toISOString(),
        categoria: categoria,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          const cliente_cambio = data.findIndex((item) => item._id === id);

          updateLocal(cliente_cambio, (item) => ({
            ...item,
            asesor_activo: asesor_id,
            asesor_activo_nombre: asesor_nombre,
            estado: 1,
            fecha_actualizacion: fecha,
            nueva_solicitud: 0,
            pago: [
              ...(item?.pago || []),
              {
                asesor: asesor_id,
                asesor_nombre: asesor_nombre,
                fecha_asignado: fecha,
              },
            ],
          }));

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "No se realizo un cambio en la base local",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err, tipo: typeof data }),
        });

        return { status: false, error: "Recarga la pagina" };
      });
  };

  // TODO: Actualizar tipo
  const actualizaGo = async (update) => {
    const token_temp = await getToken();
    return fetch(`${api}/api/clientes/actualizar`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
        client: update.cliente,
        type: update.tipo,
        name: asesores[update.id],
        id: update.id,
      },
      body: JSON.stringify(update.data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          // Tienes que actualizar local diferente por cada tipo de actualizacion
          const cliente_cambio = data.findIndex(
            (item) => item._id === update.cliente
          );

          switch (update.tipo) {
            // Ok
            case 2: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 2,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                medio: update.data.medio,
              }));
              break;
            }
            // Ok
            case 3: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 3,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                cotizaciones: [
                  ...(item?.cotizaciones || []),
                  {
                    monto: update.data.monto,
                    plazo: update.data.plazo,
                    tasa: update.data.tasa,
                    mensualidad: update.data.mensualidad,
                    total: update.data.total,
                    fecha_cotizacion: update.data.fecha,
                  },
                ],
              }));
              break;
            }
            // Ok
            case 4: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 4,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                aprobaciones: [
                  ...(item?.aprobaciones || []),
                  {
                    monto: update.data.monto,
                    plazo: update.data.plazo,
                    tasa: update.data.tasa,
                    mensualidad: update.data.mensualidad,
                    resguardo: update.data.resguardo,
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));
              break;
            }
            //Ok
            case 33: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                fecha_actualizacion: update.data.fecha_proxima,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha: update.data.fecha,
                  },
                ],
              }));
              break;
            }

            case 60: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 60,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));
              break;
            }

            case 99: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 99,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));
              break;
            }
            case 100: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 100,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));

              break;
            }
            case 101: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 101,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));

              break;
            }
            case 102: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 102,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));

              break;
            }
            case 103: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 103,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));

              break;
            }

            case 104: {
              updateLocal(cliente_cambio, (item) => ({
                ...item,
                estado: 104,
                fecha_actualizacion: update.data.fecha,
                nueva_solicitud: 0,
                notas: [
                  ...(item?.notas || []),
                  {
                    texto: update.data.texto,
                    asesor: asesores[update.id],
                    fecha_aprobacion: update.data.fecha,
                  },
                ],
              }));

              break;
            }
            default: {
            }
          }

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "Recarga la pagina",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err.toString(), tipo: typeof data }),
        });

        return { status: false, error: "No se pudo contactar con la base" };
      });
  };

  // TODO: Actualizar codigos
  const registrarPagoGo = async (id, asesor_id, numero_pago, pago, fecha) => {
    const token_temp = await getToken();

    // HACK: Almacenar documento de ficha y anexar el enlace
    const ficha = {
      fecha,
      monto: pago.monto,
      cuenta: pago.cuenta,
      concepto: pago.concepto,
      numero_pago,
      marca: pago.marca,
      razon: pago.razon,
      asesor_nombre: asesores[asesor_id],
      asesor_id,
    };

    return fetch(`${api}/api/clientes/registrar_ficha`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
        client: id,
      },
      body: JSON.stringify(ficha),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          // Realizar el cambio local
          const cliente_cambio = data.findIndex((item) => item._id === id);

          updateLocal(cliente_cambio, (item) => ({
            ...item,
            fecha_actualizacion: ficha.fecha,
            estado: 5,
            nueva_solicitud: 0,

            fichas: [...(item?.fichas || []), ficha],
          }));

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "Recarga la pagina",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err, tipo: typeof data }),
        });

        return { status: false, error: "No se pudo contactar con la base" };
      });
  };

  const uploadDoc = async (id, tipo, archivo) => {
    const token_temp = await getToken();

    const data = new FormData();

    data.append("archivo", archivo);
    data.append("nombre", id + tipo);

    const endpoint = `${api}/api/clientes/documento`;

    // return fetch(endpoint, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Content-type": "multipart/form-data",
    //     "auth-token": token_temp,
    //     id: id,
    //   },
    //   body: data,
    // })
    //   .then((res) => {
    //     console.log(res);
    //     return { status: true, error: null };
    //   })
    //   .catch((res) => {
    //     console.log(res);
    //     return { status: false, error: "No se pudo contactar con la base" };
    //   });

    return axios
      .post(endpoint, data, {
        withCredentials: false,
        headers: {
          "auth-token": token_temp,
          id: id,
        },
      })
      .then((res) => {
        console.log(res);
        return { status: true, error: null };
      })
      .catch((res) => {
        console.log(res);
        return { status: false, error: "No se pudo contactar con la base" };
      });
  };

  // TODO: Actualizar codigos
  const confirmarPagoGo = async (datos) => {
    const token_temp = await getToken();

    return fetch(`${api}/api/clientes/confirmar_ficha`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
        client: datos.id,
      },
      body: JSON.stringify(datos),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          // Realizar el cambio local
          const cliente_cambio = data.findIndex(
            (item) => item._id === datos.id
          );

          updateLocal(cliente_cambio, (item) => ({
            ...item,
            fecha_actualizacion: datos.fecha,
            estado: 6,
            nueva_solicitud: 0,
          }));

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "Recarga la pagina",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err, tipo: typeof data }),
        });

        return { status: false, error: "No se pudo contactar con la base" };
      });
  };
  const confirmarFichaGo = async (datos) => {
    const token_temp = await getToken();

    return fetch(`${api}/api/clientes/confirmar_ficha_pago`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
        client: datos.id,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          // Realizar el cambio local
          const cliente_cambio = data.findIndex(
            (item) => item._id === datos.id
          );

          updateLocal(cliente_cambio, (item) => ({
            ...item,
            fecha_actualizacion: datos.fecha,
            nueva_solicitud: 0,

            fichas: [...(item?.fichas || [])].map((item) => {
              item.estado = "true";
              return item;
            }),
          }));

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "Recarga la pagina",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err, tipo: typeof data }),
        });

        return { status: false, error: "No se pudo contactar con la base" };
      });
  };

  const sendSMSGo = async (id, telefono, tipo, fecha, uid) => {
    const token_temp = await getToken();
    return fetch(`${api}/api/clientes/sms`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token_temp,
        client: id,
        asesor_nombre: asesores[uid],
      },
      body: JSON.stringify({
        fecha,
        telefono,
        tipo,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.data.ModifiedCount === 1) {
          const cliente_cambio = data.findIndex((item) => item._id === id);

          updateLocal(cliente_cambio, (item) => {
            return {
              ...item,
              fecha_actualizacion: fecha,
              sms: [...(item.sms || []), tipo],
            };
          });

          return {
            status: true,
            error: null,
          };
        } else {
          return {
            status: false,
            error: "No se realizo un cambio en la base local",
          };
        }
      })
      .catch((err) => {
        console.log(err);

        fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "text/plain",
          },
          body: JSON.stringify({ error: err.toString(), tipo: "Error SMS" }),
        });

        return { status: false, error: "Recarga la pagina" };
      });
  };

  const registroAsistencia = () => {};

  const OrdenarPorFecha = (data) => {
    return data.sort((a, b) => {
      const datos_a = a.fecha_actualizacion.replace(",", "").split(" ");
      const datos_b = b.fecha_actualizacion.replace(",", "").split(" ");

      const fecha_a = datos_a[0].split("/").reverse();
      const fecha_b = datos_b[0].split("/").reverse();

      const hora_a = datos_a[1].split(":");
      const hora_b = datos_b[1].split(":");

      const date_a = new Date(
        fecha_a[0],
        fecha_a[1] - 1,
        fecha_a[2],
        hora_a[0],
        hora_a[1],
        hora_a[2]
      );
      const date_b = new Date(
        fecha_b[0],
        fecha_b[1] - 1,
        fecha_b[2],
        hora_b[0],
        hora_b[1],
        hora_b[2]
      );

      return date_a.getTime() > date_b.getTime()
        ? 1
        : date_a.getTime() < date_b.getTime()
        ? -1
        : 0;
    });
  };

  const getAdminList = (filter, tipo, asesor, marca) => {
    if (loading) {
      return [];
    }

    const filtroFecha = (item) => {
      const division = new Date("2024", "1", "12", "00", "00", "00");

      const fecha_texto = item.fecha_actualizacion;

      const datos_a = fecha_texto.replace(",", "").split(" ");
      const fecha_a = datos_a[0].split("/").reverse();
      const hora_a = datos_a[1].split(":");

      const date_a = new Date(
        fecha_a[0],
        fecha_a[1] - 1,
        fecha_a[2],
        hora_a[0],
        hora_a[1],
        hora_a[2]
      );

      if (tipo === "Todo") {
        return true;
      } else if (tipo === "Nueva") {
        return date_a >= division;
      } else return date_a <= division;
    };
    let objetoFiltro = () => {};

    const marcaFiltro = (item) => {
      var marca_solicitud = "";

      if (item.solicitud === null) {
        return false;
      }

      marca_solicitud = item.solicitud[item.solicitud.length - 1].marca;

      if (marca === "Todas") {
        return true;
      } else {
        return marca === marca_solicitud;
      }
    };

    objetoFiltro = {
      Nuevos: (item) => {
        return item.estado === 0;
      },
      Adicionales: (item) => {
        return item.estado === 8;
      },
      "Confirmar pago": (item) => {
        return item.estado === 5;
      },

      "Pagos confirmados": (item) => {
        return item.estado === 7;
      },

      "Pagos por revisar": (item) => {
        if (asesor === "Todos") {
          return item.estado === 9;
        }
        return item.estado === 9 && item.asesor_activo_nombre === asesor;
      },

      Reingreso: (item) => {
        if (asesor === "Todos") {
          return item.nueva_solicitud === 4 || item.nueva_solicitud === 2;
        }
        return (
          (item.nueva_solicitud === 4 || item.nueva_solicitud === 2) &&
          item.asesor_activo_nombre === asesor
        );
      },
      Asignados: (item) => {
        if (asesor === "Todos") {
          return item.estado === 1;
        }
        return item.estado === 1 && item.asesor_activo_nombre === asesor;
      },
      Contactados: (item) => {
        if (asesor === "Todos") {
          return item.estado === 2;
        }
        return item.estado === 2 && item.asesor_activo_nombre === asesor;
      },
      Seguimiento: (item) => {
        if (asesor === "Todos") {
          return item.estado === 3;
        }
        return item.estado === 3 && item.asesor_activo_nombre === asesor;
      },
      Autorizados: (item) => {
        if (asesor === "Todos") {
          return item.estado === 4;
        }
        return item.estado === 4 && item.asesor_activo_nombre === asesor;
      },

      "Asignados adicionales": (item) => {
        if (asesor === "Todos") {
          return item.estado === 9;
        }
        return item.estado === 9 && item.asesor_activo_nombre === asesor;
      },
      "Seguimiento adicionales": (item) => {
        if (asesor === "Todos") {
          return item.estado === 10;
        }
        return item.estado === 10 && item.asesor_activo_nombre === asesor;
      },

      Espera: (item) => {
        if (asesor === "Todos") {
          return item.estado === 60;
        }
        return item.estado === 60 && item.asesor_activo_nombre === asesor;
      },
      Rechazados: (item) => {
        if (asesor === "Todos") {
          return item.estado >= 100;
        }
        return item.estado >= 100 && item.asesor_activo_nombre === asesor;
      },

      Reciclaje: (item) => {
        return item.asesor_activo === "" && item.estado !== 99;
      },

      Todos: (item) => {
        if (asesor === "Todos") {
          return true;
          // return item.estado !== 99 && item.estado !== 50;
        }
        return (
          item.estado !== 99 &&
          item.asesor_activo_nombre === asesor &&
          item.estado !== 50
        );
      },
    }[filter];

    const baseFechada = data.filter((item, _) => {
      return filtroFecha(item);
    });

    const resultado = baseFechada.filter((item, _) => {
      return objetoFiltro(item);
    });
    const marca_filtro = resultado.filter((item, _) => {
      return marcaFiltro(item);
    });

    return !loading ? OrdenarPorFecha(marca_filtro) : [];
  };

  const getUserList = (filter, tipo, marca, uid) => {
    if (loading) {
      return [];
    }
    const marcaFiltro = (item) => {
      var marca_solicitud = "";
      marca_solicitud = item.solicitud[item.solicitud.length - 1].marca;

      if (marca === "Todas") {
        return true;
      } else {
        return marca === marca_solicitud;
      }
    };

    const filtroFecha = (item) => {
      const division = new Date("2024", "1", "12", "00", "00", "00");

      const fecha_texto = item.fecha_actualizacion;

      const datos_a = fecha_texto.replace(",", "").split(" ");
      const fecha_a = datos_a[0].split("/").reverse();
      const hora_a = datos_a[1].split(":");

      const date_a = new Date(
        fecha_a[0],
        fecha_a[1] - 1,
        fecha_a[2],
        hora_a[0],
        hora_a[1],
        hora_a[2]
      );

      if (tipo === "Todo") {
        return true;
      } else if (tipo === "Nueva") {
        return date_a >= division;
      } else return date_a <= division;
    };

    let objetoFiltro = () => {};

    objetoFiltro = {
      Nuevos: (item) => {
        return (
          (item.estado === 1 || item.nueva_solicitud === 2) &&
          item.asesor_activo === uid
        );
      },
      Contactados: (item) => {
        return (
          item.estado === 2 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },
      Seguimiento: (item) => {
        return (
          item.estado === 3 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },
      Autorizados: (item) => {
        return (
          item.estado === 4 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },
      "Nuevo adicional": (item) => {
        return (
          item.estado === 9 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },
      "Seguimiento adicionales": (item) => {
        return (
          item.estado === 10 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },

      "Confirmando pago": (item) => {
        return (
          item.estado === 5 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },

      "Pagos confirmados": (item) => {
        return (
          item.estado === 7 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },

      Reingreso: (item) => {
        return (
          (item.nueva_solicitud === 4 || item.nueva_solicitud === 2) &&
          item.asesor_activo === uid
        );
      },

      Espera: (item) => {
        return (
          item.estado === 60 &&
          item.asesor_activo === uid &&
          !(item.nueva_solicitud === 2)
        );
      },
      Favoritos: (item) => {
        return item.estado === 50 && item.asesor_activo === uid;
      },

      Activos: (item) => {
        return (
          (item.estado === 1 ||
            item.estado === 2 ||
            item.estado === 3 ||
            item.estado === 4 ||
            item.estado === 9 ||
            item.estado === 10) &&
          item.asesor_activo === uid
        );
      },
      Inactivos: (item) => {
        return item.estado >= 100 && item.asesor_activo === uid;
      },
      Todos: (item) => {
        return item.asesor_activo === uid && item.estado !== 99;
      },
    }[filter];

    const baseFechada = data.filter((item, _) => {
      return filtroFecha(item);
    });

    const resultado = baseFechada.filter((item, _) => {
      return objetoFiltro(item);
    });

    const filtrado_marca = resultado.filter((item, _) => {
      return marcaFiltro(item);
    });

    return !loading ? OrdenarPorFecha(filtrado_marca) : [];
  };

  const getDetails = (id) => {
    return data.find((elm, _) => {
      return elm._id === id;
    });
  };

  const getReportClents = (f_inicio, f_final, estado) => {
    if (loading) {
      return [];
    }

    const clientes = OrdenarPorFecha(data);

    const filtro = (item) => {
      if (item.solicitud === null || item.solicitud === undefined) {
        return false;
      }

      return item.solicitud.some((solicitud) => {
        const fecha_texto = solicitud.fecha;
        const datos_a = fecha_texto.replace(",", "").split(" ");
        const fecha_a = datos_a[0].split("/").reverse();
        const hora_a = datos_a[1].split(":");

        const date_a = new Date(
          fecha_a[0],
          fecha_a[1] - 1,
          fecha_a[2],
          hora_a[0],
          hora_a[1],
          hora_a[2]
        );

        return date_a >= f_inicio && date_a <= f_final;
      });

      // const fecha_texto = item.solicitud[item.solicitud.length - 1].fecha;

      // const datos_a = fecha_texto.replace(",", "").split(" ");
      // const fecha_a = datos_a[0].split("/").reverse();
      // const hora_a = datos_a[1].split(":");

      // const date_a = new Date(
      //   fecha_a[0],
      //   fecha_a[1] - 1,
      //   fecha_a[2],
      //   hora_a[0],
      //   hora_a[1],
      //   hora_a[2]
      // );

      // return date_a >= f_inicio && date_a <= f_final;
    };

    const filtro_estado = (item) => {
      return item.asesor_activo === estado;
    };

    const reporte = clientes
      .filter((item, _) => {
        return filtro(item);
      })
      // .filter((item, _) => {
      //   return filtro_estado(item);
      // })
      .map((item) => {
        return {
          nombre: item.nombre,

          //pagos: item.pago,
          //solicitudes: item.solicitud,

          monto_ultima_solicitud:
            item.solicitud[item.solicitud.length - 1].monto,
          mensaje_ultima_solicitud:
            item.solicitud[item.solicitud.length - 1].mensaje,

          fecha_actualizacion: item.fecha_actualizacion,
          fecha_ultima_solicitud:
            item.solicitud[item.solicitud.length - 1].fecha,

          telefono: item.telefono,
          correo: item.correo,
          estado: item.estado,
          estudio: item.estudio,

          asesor: item.asesor_activo_nombre,
        };
      });

    return reporte;
  };

  // const getData = () => {
  //   setLoading(true);
  //   getToken().then((res) => {
  //     fetch(`${api_old}/api/clientes/getList`, {
  //       method: "GET",
  //       mode: "cors",
  //       headers: {
  //         "Content-type": "application/json",
  //         "auth-token": res,
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Headers": "*",
  //       },
  //     })
  //       .then((res) => {
  //         if (res.ok) {
  //           return res.json();
  //         }
  //         throw new Error(res);
  //       })
  //       .then((res) => {
  //         if (res.error) {
  //           throw new Error(res);
  //         }
  //         setError("No hay error");
  //         setLoading(false);
  //         setData(res);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   });
  // };

  // const asignar = (id, asesor_nombre, asesor, fecha, categoria) => {
  //   return fetch(`${api}/api/clientes/asignar`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id: id,
  //       asesor_id: asesor,
  //       asesor_nombre: asesor_nombre,
  //       fecha: fecha,
  //       categoria: categoria,
  //     }),
  //   })
  //     .then(async (res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         data[cliente_cambio].asesor_activo = asesor;
  //         data[cliente_cambio].asesor_activo_nombre = asesor_nombre;
  //         data[cliente_cambio].estado = 1;
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;
  //         data[cliente_cambio].pago = [
  //           {
  //             asesor: asesor,
  //             asesor_nombre: asesor_nombre,
  //             fecha_asignado: fecha,
  //           },
  //         ];
  //         //  Si se modifico
  //         return true;
  //       } else {
  //         //  No se modifico
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const getList = (filter) => {
  //   if (loading) {
  //     return [];
  //   }

  //   let objetoFiltro = () => {};

  //   objetoFiltro = {
  //     Nuevos: (item) => {
  //       return item.estado === 1;
  //     },
  //     Seguimiento: (item) => {
  //       return item.estado === 2;
  //     },
  //     Autorizados: (item) => {
  //       return item.estado === 3;
  //     },
  //     "Nuevo adicional": (item) => {
  //       return item.estado === 6;
  //     },
  //     "Seguimiento adicionales": (item) => {
  //       return item.estado === 7;
  //     },

  //     "Confirmando pago": (item) => {
  //       return item.estado === 4;
  //     },

  //     Reingreso: (item) => {
  //       return item.nueva_solicitud === 2;
  //     },

  //     Espera: (item) => {
  //       return item.estado === 60;
  //     },
  //     Favoritos: (item) => {
  //       return item.estado === 50;
  //     },

  //     Activos: (item) => {
  //       return (
  //         item.estado === 1 ||
  //         item.estado === 2 ||
  //         item.estado === 3 ||
  //         item.estado === 6 ||
  //         item.estado === 7
  //       );
  //     },
  //     Inactivos: (item) => {
  //       return item.estado >= 100;
  //     },
  //     Todos: (item) => {
  //       return item.estado !== 99 && item.estado !== 50;
  //     },
  //   }[filter];

  //   const resultado = data.filter((item, _) => {
  //     return objetoFiltro(item);
  //   });

  //   return !loading ? OrdenarPorFecha(resultado) : [];
  // };

  // const clienteContactado = (id, cotizacion, fecha) => {
  //   return fetch(`${api}/api/clientes/clienteContactado`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       cotizacion,
  //       fecha,
  //     }),
  //   })
  //     .then(async (res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         //  Muta el estado local
  //         data[cliente_cambio].estado = 2;
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;

  //         if (!data[cliente_cambio].cotizaciones)
  //           data[cliente_cambio].cotizaciones = [];

  //         data[cliente_cambio].cotizaciones.push({
  //           monto: cotizacion.monto,
  //           plazo: cotizacion.plazo,
  //           tasa: cotizacion.tasa,
  //           mensualidad: cotizacion.mensualidad,
  //           total: cotizacion.total,
  //           fecha_cotizacion: fecha,
  //         });

  //         console.log("Datos actualizados");
  //         return true;
  //       } else {
  //         //  No se modifico
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const clienteAutorizado = (id, aprobacion, fecha) => {
  //   return fetch(`${api}/api/clientes/clienteAutorizado`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       aprobacion,
  //       fecha,
  //     }),
  //   })
  //     .then(async (res) => {
  //       console.log(res.status);
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         //  Muta el estado local
  //         data[cliente_cambio].estado = 3;
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;

  //         if (!data[cliente_cambio].aprobaciones)
  //           data[cliente_cambio].aprobaciones = [];

  //         data[cliente_cambio].aprobaciones.push({
  //           monto: aprobacion.monto,
  //           plazo: aprobacion.plazo,
  //           tasa: aprobacion.tasa,
  //           mensualidad: aprobacion.mensualidad,
  //           resguardo: aprobacion.resguardo,
  //           fecha_aprobacion: fecha,
  //         });

  //         console.log("Datos actualizados");
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const aplazarContacto = (id, fecha) => {
  //   return fetch(`${api}/api/clientes/aplazarContacto`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       fecha,
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         //  Muta el estado local
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;

  //         console.log("Datos actualizados");
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const registrarPago = (id, pago, fecha) => {
  //   return fetch(`${api}/api/clientes/registrarPago`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       pago,
  //       fecha,
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         //  Muta el estado local
  //         data[cliente_cambio].estado = 4;
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;

  //         data[cliente_cambio].pago[0].monto = pago.monto;
  //         data[cliente_cambio].pago[0].medio = pago.medio;
  //         data[cliente_cambio].pago[0].cuenta = pago.cuenta;
  //         data[cliente_cambio].pago[0].fecha_pagado = fecha;
  //         data[cliente_cambio].pago[0].confirmado = false;

  //         console.log("Datos actualizados");
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const rechazarCliente = (id, fecha) => {
  //   return fetch(`${api}/api/clientes/rechazar`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       fecha,
  //     }),
  //   })
  //     .then(async (res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         data[cliente_cambio].estado = 99;
  //         data[cliente_cambio].nueva_solicitud = 0;
  //         data[cliente_cambio].fecha_actualizacion = fecha;

  //         //  Si se modifico
  //         return true;
  //       } else {
  //         //  No se modifico
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  // const cancelar = (id, status, fecha) => {
  //   return fetch(`${api}/api/clientes/cancelar`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-type": "application/json",
  //       "auth-token": token,
  //     },
  //     body: JSON.stringify({
  //       id,
  //       status,
  //       fecha,
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const cliente_cambio = data.findIndex((item) => item._id === id);

  //         //  Muta el estado local
  //         data[cliente_cambio].estado = status;
  //         data[cliente_cambio].fecha_actualizacion = fecha;
  //         data[cliente_cambio].nueva_solicitud = 0;

  //         console.log("Datos actualizados");
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return false;
  //     });
  // };

  const retirar = (id, fecha) => {
    return fetch(`${api}/api/clientes/retirar`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        id,
        fecha,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          const cliente_cambio = data.findIndex((item) => item._id === id);

          data[cliente_cambio].asesor_activo = "";
          data[cliente_cambio].asesor_activo_nombre = "";
          data[cliente_cambio].fecha_actualizacion = fecha;

          //  Si se modifico
          return true;
        } else {
          //  No se modifico
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return {
    // Funciones de nuevo backend
    getDataGo,
    asignarGo,
    actualizaGo,
    registrarPagoGo,
    confirmarPagoGo,
    confirmarFichaGo,
    uploadDoc,

    sendSMSGo,

    asistencia,
    registroAsistencia,
    // Administracion local de base
    getAdminList,
    getUserList,
    getDetails,

    getReportClents,

    // Funciones de backend en JavaScript
    //getData,
    //asignar,
    //getList,
    //clienteContactado,
    //clienteAutorizado,
    //aplazarContacto,
    //registrarPago,
    //rechazarCliente,
    //cancelar,

    retirar,

    // Variables de estado
    loading,
    error,
  };
};

export default useDatabase;
