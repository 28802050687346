/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/UserProvider";

import SearchBar from "../../components/SearchBar";
import ChipContainer from "../../components/ChipContainer";
import ClientDetails from "../../components/ClientDetails";
import ClientsContainer from "../../components/ClientsContainer";
import styles from "./DashboardComponent.module.scss";
import SideMenuComponent from "../../components/SideMenuComponent/SideMenuComponent";

const chips = [
  "Nuevos",
  "Contactados",
  "Seguimiento",
  "Autorizados",

  // "Nuevo adicional",
  // "Seguimiento adicionales",

  "Confirmando pago",
  "Pagos confirmados",
  "Reingreso",

  "Espera",

  "Activos",
  "Inactivos",
  "Todos",
];

const DashboardComponent = ({ database }) => {
  const [activeChip, setActiveChip] = useState("Nuevos");
  const [baseChip, setBaseChip] = useState("Nueva");
  const [marcaChip, setMarcaChip] = useState("Todas");
  const [textFilter, setTextFilter] = useState("");

  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [activeDetails, setActiveDetails] = useState(false);
  const [activeId, setActiveId] = useState("");

  const [showSidemenu, setShowSidemenu] = useState(false);

  const { userData } = useContext(UserContext);

  const updateClientes = () => {
    setClientes(
      database.getUserList(activeChip, baseChip, marcaChip, userData.data.uid)
    );
  };

  useEffect(() => {
    database.getDataGo();
  }, []);

  useEffect(() => {
    if (!database.loading) {
      updateClientes();
    }
  }, [activeChip, baseChip, marcaChip, database]);

  useEffect(() => {
    if (!!textFilter) {
      setClientesFiltrados(
        clientes.filter((item) => {
          return (
            item.telefono.toUpperCase().includes(textFilter.toUpperCase()) ||
            item.correo.toUpperCase().includes(textFilter.toUpperCase()) ||
            item.nombre.toUpperCase().includes(textFilter.toUpperCase())
          );
        })
      );
    } else setClientesFiltrados(clientes);
  }, [textFilter, activeChip, clientes]);

  const onClientClick = (id) => {
    setActiveId(id);
    setActiveDetails(database.getDetails(id));
    setShowDetails(true);
  };

  const fechaActual = (day = false) => {
    const timestamp = new Date();

    const after = new Date();
    after.setDate(timestamp.getDate() + 1);
    after.setHours(0, 0, 0, 0);

    return day
      ? after.toLocaleString("es-MX", {
          timeZone: "America/Mexico_City",
        })
      : timestamp.toLocaleString("es-MX", {
          timeZone: "America/Mexico_City",
        });
  };

  // Funcion reconstruida
  const clienteContactado = async (id, medio) => {
    const resultado = await database.actualizaGo({
      tipo: 2,
      cliente: id,
      id: userData.data.uid,
      data: {
        medio: medio,
        fecha: fechaActual(),
      },
    });

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  // Funcion reconstruida
  const clienteSeguimiento = async (id, cotizacion) => {
    const resultado = await database.actualizaGo({
      tipo: 3,
      cliente: id,
      id: userData.data.uid,
      data: {
        monto: cotizacion.monto,
        plazo: cotizacion.plazo,
        tasa: cotizacion.tasa,
        mensualidad: cotizacion.mensualidad,
        total: cotizacion.total,
        fecha: fechaActual(),
      },
    });

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };
  // Funcion reconstruida
  const clienteAutorizado = async (id, aprobacion) => {
    const resultado = await database.actualizaGo({
      tipo: 4,
      cliente: id,
      id: userData.data.uid,
      data: {
        monto: aprobacion.monto,
        plazo: aprobacion.plazo,
        tasa: aprobacion.tasa,
        mensualidad: aprobacion.mensualidad,
        resguardo: aprobacion.resguardo,
        fecha: fechaActual(),
      },
    });

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  const agregarNota = async (id, nota) => {
    const resultado = await database.actualizaGo({
      tipo: 33,
      cliente: id,
      id: userData.data.uid,
      data: {
        texto: nota,
        fecha: fechaActual(),
        fecha_proxima: fechaActual(),
      },
    });

    if (resultado) {
      //  Recarga la base
      updateClientes();
      setActiveDetails({ solicitud: [{}] });
      //  Oculta los detalles
      setShowDetails(false);
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
    return false;
  };

  // Funcion reconstruida
  const aplazarContacto = async (id, nota) => {
    const resultado = await database.actualizaGo({
      tipo: 33,
      cliente: id,
      id: userData.data.uid,
      data: {
        texto: nota,
        fecha: fechaActual(),
        fecha_proxima: fechaActual(true),
      },
    });

    if (resultado) {
      //  Recarga la base
      updateClientes();
      setActiveDetails({ solicitud: [{}] });
      //  Oculta los detalles
      setShowDetails(false);
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
    return false;
  };

  // Funcion reconstruida
  const cancelar = async (id, codigo, nota) => {
    const resultado = await database.actualizaGo({
      tipo: codigo,
      cliente: id,
      id: userData.data.uid,
      data: {
        texto: nota,
        fecha: fechaActual(),
      },
    });

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  //Funcion reconstruida
  const registrarPago = async (id, numero_pago, pago) => {
    const resultado = await database.registrarPagoGo(
      id,
      userData.data.uid,
      numero_pago,
      pago,
      fechaActual()
    );

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  const confirmarPago = async () => {
    const resultado = await database
      .confirmarPago
      // id,
      // userData.data.uid,
      // numero_pago,
      // pago,
      // fechaActual()
      ();

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };
  const confirmarFicha = async () => {
    const resultado = await database.confirmarFicha();

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  const uploadDoc = async (id, tipo, archivo) => {
    const resultado = await database.uploadDoc(id, tipo, archivo);

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    console.log(resultado);
  };

  const enviarSMS = async (id, telefono, tipo) => {
    const resultado = await database.sendSMSGo(
      id,
      telefono,
      tipo,
      fechaActual(),
      userData.data.uid
    );

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  // const smsBar =
  //   userData.data.uid === "o3wE9g1G98hWO2TKJaefdzXl6XO2" || // Liz
  //   userData.data.uid === "30CN2MSq8SWSvniK0te3krQiT913" || // Anel
  //   userData.data.uid === "vAFXt7CrEGboKdvj0h1hniRBYSg2" || // Diana
  //   userData.data.uid === "ymglgJpYJHM98coGj0dxfjSEE813" || // Sonia
  //   userData.data.uid === "2TBSb8RFC5cfYm8yxw07cX7iQyr1"; // Admin

  // const sala =
  //   userData.data.uid === "30CN2MSq8SWSvniK0te3krQiT913" // Anel
  //     ? "En Bien Crediticio estaremos dando seguimiento a tu solicitud por el numero 5589427376  "
  //     : userData.data.uid === "o3wE9g1G98hWO2TKJaefdzXl6XO2" // Liz
  //     ? "En Bien Crediticio estaremos dando seguimiento a tu solicitud por el numero 5589427381  "
  //     : userData.data.uid === "vAFXt7CrEGboKdvj0h1hniRBYSg2" // Diana
  //     ? "En Bien Crediticio estaremos dando seguimiento a tu solicitud por el numero 5589427251  "
  //     : userData.data.uid === "ymglgJpYJHM98coGj0dxfjSEE813" // Sonia
  //     ? "En Bien Crediticio estaremos dando seguimiento a tu solicitud por el numero 5589427381  "
  //     : "En Bien Crediticio estaremos dando seguimiento a tu solicitud por el numero 5589427376 ";

  return (
    <div className={styles.contenedor}>
      <div className={styles.saludo}>
        <div onClick={() => setShowSidemenu(true)}>
          <p>Hola</p>
          <h1>{userData.data.displayName}</h1>
          <p>Ver. 1.2</p>
        </div>

        <div className={styles.busquedaContainer}>
          <span className="material-symbols-outlined">{"travel_explore"}</span>

          <SearchBar filter={textFilter} setFilter={setTextFilter} />
        </div>
      </div>

      {/* <div className={styles.meta}>
        <p>Llevas hasta hoy</p>
        <h1>-</h1>
      </div> */}

      {/* <div className={styles.busqueda}>
        <input></input>
        <button>x</button>
      </div> */}

      <div className={styles.cuerpo}>
        <ChipContainer
          activeChip={baseChip}
          setActiveChip={setBaseChip}
          chips={["Nueva", "Vieja", "Todo"]}
        />
        <ChipContainer
          activeChip={marcaChip}
          setActiveChip={setMarcaChip}
          chips={[
            "Todas",
            "Solucion Financiera",
            "Bien Crediticio",
            "Prestamo 24/7",
            "Prestamo 24",
          ]}
        />
        <ChipContainer
          activeChip={activeChip}
          setActiveChip={setActiveChip}
          chips={chips}
          count={clientesFiltrados.length}
        />

        {/* HACK: Implementar RecicledView */}
        <ClientsContainer
          clientes={clientesFiltrados}
          showDetails={showDetails}
          onClientClick={onClientClick}
          activeId={activeId}
          loading={database.loading}
          error={database.error}
        />
      </div>

      <ClientDetails
        details={activeDetails}
        show={showDetails}
        hide={() => {
          setShowDetails(false);
          setActiveId("");
        }}
        clienteContactado={clienteContactado}
        clienteSeguimiento={clienteSeguimiento}
        clienteAutorizado={clienteAutorizado}
        registrarPago={registrarPago}
        uploadDoc={uploadDoc}
        confirmarPago={confirmarPago}
        confirmarFicha={confirmarFicha}
        aplazarContacto={aplazarContacto}
        agregarNota={agregarNota}
        cancelar={cancelar}
        rechazar={cancelar}
        admin={false}
        enviarSMS={enviarSMS}
      />

      <SideMenuComponent
        show={showSidemenu}
        setShow={setShowSidemenu}
        asistencia={database.asistencia}
        registro={database.registroAsistencia}
      />

      <div className={styles.extraButtons}>
        {/* <button
          className={styles.addButton}
          onClick={() => {
            database.getDataGo();
          }}
        >
          <span className="material-symbols-outlined">add</span>
        </button> */}
        {/* <Link to={"/add"} className={styles.addButton}>
          <span className="material-symbols-outlined">add</span>
        </Link> */}

        {/* TODO:Botones de desplazamiento rapido */}
        {/* <button classclassName={styles.scrollButton}>
          <span class="material-symbols-outlined">arrow_upward</span>
        </button>
        <button classclassName={styles.scrollButton}>
          <span className="material-symbols-outlined">arrow_downward</span>
        </button> */}
        {userData.data.uid === "qfkuuBGFx6VmJ7f8AAvKi39IZSD2" || // Juan Luis
        userData.data.uid === "sE2jlhd26aO7f38x8a5m946hAhg1" || // wiki
        userData.data.uid === "CLD4kMC1YsXzjtj8jpyUcKxbfTq2" || // Tina
        userData.data.uid === "" ? ( // Silvia
          <Link to={"/admin"} className={styles.adminButton}>
            <span className="material-symbols-outlined">manage_accounts</span>
          </Link>
        ) : null}
        {userData.data.uid === "2TBSb8RFC5cfYm8yxw07cX7iQyr1" ? ( // Betty
          <Link to={"/coordinacion"} className={styles.adminButton}>
            <span className="material-symbols-outlined">manage_accounts</span>
          </Link>
        ) : null}
        {userData.data.uid === "hL62KFPq5CY4x9FHGIexTp5aCZS2" ? ( // Citla
          <Link to={"/pilares"} className={styles.adminButton}>
            <span className="material-symbols-outlined">manage_accounts</span>
          </Link>
        ) : null}
        {userData.data.uid === "e8iTet3ZducBTeosJu9yf2DOkCs1" ? ( // Jesus
          <Link to={"/capacitacion"} className={styles.adminButton}>
            <span className="material-symbols-outlined">manage_accounts</span>
          </Link>
        ) : null}
        {userData.data.uid === "pWoiwgbqoCe0y72QOo5akQ6uOuW2" ? ( // Silvia
          <Link to={"/asignacion"} className={styles.adminButton}>
            <span className="material-symbols-outlined">manage_accounts</span>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardComponent;
